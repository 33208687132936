.filterContainer{
    /* margin: 20px 0; */
    background-color: #fff;
    /* padding: 0 20px;
    height: 100vh;
    border-radius: 30px; */
    overflow-y: auto;
}

.filterSection{
    display: flex;
    justify-content: space-between;
    padding: 8.5px 20px;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.filterSection .clearSearch,.filterSection .searchFilter{
    background-color: #ba102c;
    border: 0;
    color: white;
    font-size: 15px;
    margin-right: 0px;
    border-radius: 5px;
    padding: 5px 12px;
    text-decoration: none;    
}

.filterSection .clearSearch.disable,.filterSection .searchFilter.disable{
    opacity: 0.5;
    pointer-events: none;
}

.filterValues{
    max-height: 200px;
    overflow-y: auto;
}

.filterValues .freeText{
    display: flex;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
}

.filterValues .freeText input{
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    margin: 0;
    border: 1px solid #ccc;
}

.filterValues .freeText span{
    width: 10%;
    text-align: center;
    cursor: pointer;
}

.filterValues .freeText p{
    color: red;
    padding: 5px 0 0 !important;
}

.filterValues .filterText p{
    border-radius: 10px;
    border: 1px solid #563426;
    display: inline-flex;
    align-items: center;
    margin: 7px;
    cursor: default;
    padding: 3px 3px 3px 10px !important;
}

.filterValues .filterText{
    margin: 10px;
}

.filterValues .filterText p span{
    cursor: pointer;
    font-size: 20px;
}
