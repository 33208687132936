.gridContainer{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    gap: 15px;
    margin: 10px;
    padding: 0 10px;
}
.assetGridContainer{
    width: 100%;
    background-color: #fff;
    position: relative;
    border-radius: 10px;
    font-size: 12px;
    min-width: 150px;
}

.assetGridContainer:hover .assetGridIconsContainer{
    display: block;
}

.imgContainer{
    padding: 10px;
    cursor: pointer;
    background-image: url("../../Assets/bg.png");
    width: 100%;
    text-align: center;
    height: 180px;
    display: flex;
    align-items: center;
}
.imgContainer img{
    max-width: 150px;
    margin: 0px auto;
    width: auto;
    max-height: 160px;
}

.assetInfoContainer{
    text-align: left;
    max-width: 100%;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
}

.assetInfoContainer div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.assetGridIconsContainer{
    position: absolute;
    right: 8px;
    top: 5px;
    display: none;
}

.downloadIcon span{
    cursor: pointer;
}

.selectAsset,.downloadIcon{
    line-height: 1;
}

.selectAsset .iconActive{ 
    display: block;
}

.assetGridIconsContainer span{
    font-size: 20px;
    cursor: pointer;
}

.assetGridIconsContainer.gridSelected{
    display: block !important;
}

.downloadImage{
    color: #000;
    }

.recordCountContainer{
    display: flex;
    padding: 10px;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid darkgrey;
    align-items: center;
}

.selectBtn,.downloadAllBtn{
    background-color: #ba102c;
    border: 0;
    color: white;
    font-size: 15px;
    margin-right: 20px;
    border-radius: 5px;
    padding: 5px 12px;
    text-decoration: none;
}

a[disabled]{
    pointer-events: none;
    opacity: 0.5;
}

.btnContainer{
    display: flex;
    align-items: center;
}

.assetGridContainer .psdCard{
    position: absolute;
    top: 0;
    left: 0;
    color: #2b226d;
    font-weight: bold;
    background-color: #95caff;
    padding: 2px 5px;
}

@media (max-width: 1700px) {
    .gridContainer{
        grid-template-columns: repeat(6,1fr);
    }
}

@media (max-width: 1500px) {
    .gridContainer{
        grid-template-columns: repeat(5,1fr);
    }
}

@media (max-width: 1300px) {
    .gridContainer{
        grid-template-columns: repeat(4,1fr);
    }
}

@media (max-width: 1100px) {
    .gridContainer{
        grid-template-columns: repeat(3,1fr);
    }
}

@media (max-width: 767px) {
    .gridContainer{
        grid-template-columns: repeat(2,1fr);
    }
}


