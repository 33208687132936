.portalValidateContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 90px);
    background-color: #e9ebee;
}

.emailValidation{
    text-align: center;
    max-width: 300px;
}

.portalEmailBtn{
    display: block;
    margin: 10px auto;
}

.errorMessage{
    display: block;
    color: red;
}