.App {
  /* text-align: center; */
  font-family: sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.headerRowContainer{
  margin: 0 !important;
  padding: 0 !important;
}

.headerColContainer{
  padding: 0 !important;
}

.assetResultContainer{
  background-color: #e9ebee;
  margin: 0 !important;
}

.headerRowContainer{
  margin: 0 !important;
  padding: 0 !important;
}

.headerColContainer{
  padding: 0 !important;
}

.leftContainer{
  border-right: 2px solid #ccc;
  background-color: #fff;
}

.email-text-input .data-labels{
  display: flex !important;
  flex-wrap: wrap !important;
}

.err-text{
  font-size: 12px;
  color: red;
}

.required::after {
  content:"*";
  color: red;
  font-size: 14px;
}

.ajaxLoading{
    right: 0;
    display: block;
    left: 0;
    top: 0;
    z-index: 9999;
    background-image: url("Assets/ajaxloader.svg");
    background-color: rgba(255,255,255,.6);
    background-repeat: no-repeat;
    background-position: center;
    bottom: 0;
    position: fixed;
}

.accordion{
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}

.accordion-button{
  font-weight: bold;
}

.paginationContainer{
  display: flex;
}

.paginationContainer ul{
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.paginationContainer ul li{
  padding: 5px;
}

.paginationContainer ul li a{
  color: #000;
}

.paginationContainer ul li.selected a{
  color: 	#ba102c;
  font-weight: bold;
}

.filterContainer .accordion{
  padding: 10px 20px;
}

.filterContainer .accordion-body{
  padding: 0px;
}

.filterContainer .accordion-button{
  font-size: 14px !important;
  padding: 10px !important;
}

.filterContainer .accordion .accordion-body p{
  font-size: 13px;
  cursor: pointer;
  padding: 6px 10px;
  margin: 0;
}

.filterContainer .accordion .accordion-body p:hover,.filterContainer .accordion .accordion-body p.active{
  background-color:#e9ebee;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.assetFormContainer .form-control{
  background-color: #e9ebee;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal{
  padding: 0 !important;
  overflow: hidden !important;
}
.modal-dialog{
  max-width: 100% !important;
  margin: 0 !important;
}
.modal-content{
  height: 100vh;
}
.modal-body{
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.portalGridContainer .portalGrid{
  grid-template-columns: repeat(9,1fr);
}

@media (max-width: 1900px) {
  .portalGridContainer .portalGrid{
      grid-template-columns: repeat(8,1fr);
  }
}

@media (max-width: 1700px) {
  .portalGridContainer .portalGrid{
      grid-template-columns: repeat(7,1fr);
  }
}

@media (max-width: 1700px) {
  .portalGridContainer .portalGrid{
      grid-template-columns: repeat(6,1fr);
  }
}

@media (max-width: 1300px) {
  .portalGridContainer .portalGrid{
      grid-template-columns: repeat(5,1fr);
  }
}

@media (max-width: 1100px) {
  .portalGridContainer .portalGrid{
      grid-template-columns: repeat(4,1fr);
  }
}

@media (max-width: 767px) {
  .portalGridContainer .portalGrid{
      grid-template-columns: repeat(3,1fr);
  }
}

@media (max-width: 767px) {
  .portalGridContainer .portalGrid{
      grid-template-columns: repeat(3,1fr);
  }
}

@media (max-width: 580px) {
  .portalGridContainer .portalGrid{
      grid-template-columns: repeat(2,1fr);
  }
}

