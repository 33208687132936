.assetDetailContainer{
    display: flex;
    width: 100%;
}
.popupContentContainer{
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
}
.imageDetailContainer{
    display: flex;
    justify-content: center;
    align-items: baseline;
    background-image: url("../../Assets/bg.png");
    position: relative;
    padding: 0 !important;
    flex-wrap: wrap;    
}
.imageDetailContainer img{
    height: auto;
    max-width: 100%;
}

.contentDetailContainer{
    padding: 15px 15px 0;
    background-color: #e9ebee;
}

.contentDetailContainer h2{
    font-size: 1.5rem;
}

.assetInfo{
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    color: #000;
}


.assetTitle{
    font-weight: 700;
    display: block;
    margin-top: 5px;
    text-transform: capitalize;
}

.assetValue{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    cursor: default;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
}

.assetNavBar{
    width: 100%;
    background-color: #000;
    padding: 6px;
    display: flex;
    justify-content: space-between;
}

.viewImgContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftNav{
    display: flex;
    align-items: center;
}

.backArrow{
    color: #fff;
    cursor: pointer;
}

.navDownload{
    color: #fff;
    cursor: pointer;
}

.downloadImage{
    display: flex;
    align-items: center;
    text-decoration: none;
}