.downloadPopup .modal-dialog{
   width: 300px;
   height: inherit;
   margin: auto !important;
   display: flex;
   justify-content: center;
   align-items: center;
}

.downloadPopup .modal-content{
   height: auto;
}

.downloadPopup .modal-content .modal-header{
    display: flex;
    align-items: center;
}

.downloadPopup .modal-content .modal-header h1{
    font-size: 1.5rem;
    margin: 0;
}

.downloadPopupContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.downloadPopupContainer .downloadAsset{
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    padding: 10px 0 10px 40px;
    color: #000;
    border-bottom: 1px solid #dee2e6;
}

.downloadPopupContainer .downloadAsset:hover{
    background-color:#dee2e6;
}

.downloadAsset .convertType{
    padding-left: 20px;
}