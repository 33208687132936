.headerRowContainer{
    margin: 0 !important;
    padding: 0 !important;
}

.headerColContainer{
    padding: 0 !important;
}

.assetResultContainer{
    background-color: #e9ebee;
    margin: 0 !important;
    width: 100%;
}

.leftContainer{
    border-right: 2px solid #ccc;
    background-color: #fff;
}

.portalContainer{
    display: flex;
    min-height: calc(100vh - 90px);
    height: 100%;
}
  
  