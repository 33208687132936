.errorPopup .modal-dialog{
   width: 100%;
   height: inherit;
   margin: auto !important;
   display: flex;
   justify-content: center;
   align-items: center;
   max-width: 500px !important;
}

.errorPopup .modal-content{
   height: auto;
}

.errorPopup .modal-content .modal-header{
    display: flex;
    align-items: center;
}

.errorPopup .modal-content .modal-header h1{
    font-size: 1.5rem;
    margin: 0;
    color: red;
}

.errorPopup .errorPopupContainer{
    padding: 15px;
    width: 100%;
}

/* .errorPopup .errorPopupContainer .errorContent{
    
} */

.errorPopup .errorPopupContainer .errorValue{
    word-wrap: break-word;
}

