.assetFormContainer{
    padding: 10px;
}

.assetForm{
    padding: 10px;
}

.formLabel{
    font-size: 12px;
}

.formControl{
    font-size: 12px !important;
}
.btnContainer{
    text-align: center;
}
.formSubmitBtn{
    margin-top: 15px;
    font-size: 12px !important;
}

.toEmailContainer input{
    padding: 0.1em 0.1em !important;
}

.toEmailContainer p{
    margin-top: 5px;
    font-size: 12px;
}

input:focus,textarea:focus{
    box-shadow: none !important;
}

.assetSubmitForm .err-text{
    font-size: 12px;
}

.errorContainer .errorText{
    color: red;
    text-align: center;
    margin-top: 5px;
}