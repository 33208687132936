.headerRowContainer{
    margin: 0 !important;
    padding: 0 !important;
}

.headerColContainer{
    padding: 0 !important;
}

.assetResultContainer{
    background-color: #e9ebee;
    margin: 0 !important;
    width: 100%;
}

.leftContainer{
    border-right: 2px solid #ccc;
    background-color: #fff;
}

.previewPortalContainer{
    display: flex;
    min-height: calc(100vh - 90px);
    height: 100%;
}

.assetSuccessContainer{
    width: 100%;
    height: calc(100vh - 90px);
    justify-content: center;
    align-items: center;
}

.resultStatus{
    text-align: center;
}

.rightContainer{
    display: flex;
    flex-direction: column;
}
  
  